<template>
  <div class="me-wrapper-box">
    <div class="me-wrapper">
      <div class="me-banner">
        <div class="_back" @click="$router.go(-1)"><img src="../status/img/icon_back.png" /></div>
        <div class="_img"><img :src="categoryInfo.head_image" /></div>
      </div>
      <div class="jk-banner">
        <ul>
          <li class="_img" v-for="item of goodsList" @click="jumpGoodsDetail(item)"  >
            <img :src="item.images" v-if="item.is_shelves==1" />
            <img :src="item.images_un" v-if="item.is_shelves==0"  />
          </li>
        </ul>
      </div>
      <div class="jk-foot">
        <div class="_img"><img :src="categoryInfo.foot_image" /></div>
      </div>
    </div>
    <div class="coming-soon" v-if="comingSoon" >
      <div class="_content">
        <div class="_window"><img src="../status/img/icon_coming.png" /></div>
        <div class="_closed" @click="comingSoon = false"><img src="../status/img/icon_closed.png" /></div>
      </div>
    </div>
    <Footer :step="2" />
  </div>
</template>

<script>
import Footer from './components/footer'
export default {
  mixins: [],
  components: {Footer},
  data() {
    return {
      id :"",
      categoryInfo :{},
      goodsList:[],
      comingSoon: false,
    };
  },

  created() {
    this.id = this.$route.query.id;
  },

  mounted() {
this.getCategoryDetail();
  },

  methods: {
    jumpGoodsDetail(item){
      if(item.coming_flag==1){
        this.comingSoon=true;
      }else{
        this.$router.push({path:'/goodsInfo',query:{id:item.id}})
      }
    },
    async getCategoryDetail() {
      // 获取分类列表
      let {data,code} = await this.$ajax.gateway("/api/api_get_category_by_id", {
        "METHOD": "get",
         id: this.id
      });
      this.categoryInfo = data.info;
      this.goodsList = data.goods;
    }
  }
};
</script>

<style lang="less" scoped>
  @import "../status/css/page.less";

  .jk-banner {
    padding: 0 0.5rem;
    ul li {
      margin-top: 0.2rem;
    }
    margin-bottom: 2rem;
  }
  .jk-foot {
    width: 100%;
    padding: 0.6rem 0 0.3rem;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 1.5rem;
  }

  .coming-soon {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.45);
    ._content {
      width: 100%;
      position: absolute;
      top: 2rem;
      left: 0;
      ._window {
        padding: 0 0.75rem;
        img {
          display: block;
          width: 100%;
        }
      }
      ._closed {
        padding: 0.4rem 0;
        text-align: center;
        img {
          width: 1.2rem;
        }
      }
    }
  }
</style>
